/**
 * jQuery Yii ListView extension.
 *
 * @author Sergey Glagolev <glagolev@shogo.ru>
 * @link https://github.com/shogodev/argilla/
 * @copyright Copyright &copy; 2003-2014 Shogo
 * @license http://argilla.ru/LICENSE
 */

;(function($) {
  if (jQuery.fn.yiiListView) {
    /**
     * @param id
     */
    $.fn.yiiListView.beforeAjaxHandler = function (id) {
      $('body').trigger('yiiListViewUpdating', [id]);
    };

    /**
     * @param id
     * @param data
     */
    $.fn.yiiListView.afterAjaxHandler = function (id, data) {
      $('body').trigger('yiiListViewUpdated', [id, data]);
    };
  }
})(jQuery);